import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { DataGrid } from "@mui/x-data-grid";
import CircularProgress from "@mui/material/CircularProgress";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Sidebar from '../../components/sidebar/Sidebar';
import Navbar from '../../components/navbar/Navbar';
import { allCategory } from "../../datatablesource";
import { useNavigate } from 'react-router-dom';

const AddCatUserTable = () => {
    const [categroydata, setcategroydata] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const [filteredData, setFilteredData] = useState([]);
    const navigate = useNavigate();

    const fetchDatacategory = async () => {
        setIsLoading(true);
        try {
            const response = await axios.get('https://backend.mysecretlit.com/api/user/getHomeGenre');
            const formattedData = response.data.data.reverse().map((item) => ({
                ...item,
                id: item.id,
            }));
            setcategroydata(formattedData);
           
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setIsLoading(false);
        }
    };
console.log('filteredData',filteredData)
    useEffect(() => {
        fetchDatacategory();
    }, []);

    useEffect(() => {
        if (categroydata.length > 0) {
            const filtered = categroydata.filter(item =>
                item.genreName.toLowerCase().includes(searchQuery.toLowerCase())
            );
            setFilteredData(filtered);
        }
    }, [searchQuery, categroydata]);

    const handleView = (category) => {
        console.log('Navigating with category:', category.books);
        navigate('/Dashboard/add-selected-category-home-details-pg', { state: { dataa: category.books } });
    };

    const actionColumn = [
        {
            field: "action",
            headerName: "Action",
            width: 150,
            renderCell: (params) => (
                <div className="cellAction">
                    <div
                        className="viewButton"
                        onClick={() => handleView(params.row)}
                    >
                        View
                    </div>
                </div>
            ),
        },
    ];

    return (
        <div className='list'>
            <Sidebar />
            <div className="listContainer">
                <Navbar headingmain="All Home Page Categories Render Book" showSearchBar={false} />
                <div className="datatable" style={{ paddingTop: 40 }}>
                    <ToastContainer />
                    {isLoading ? (
                        <div className="loadingSpinner">
                            <CircularProgress color="error" />
                        </div>
                    ) : categroydata.length === 0 ? (
                        <p>No data available</p>
                    ) : (
                        <DataGrid
                            className="datagrid"
                            rows={filteredData}
                            columns={allCategory.concat(actionColumn)}
                            pageSize={15}
                            rowsPerPageOptions={[9]}
                        />
                    )}
                </div>
            </div>
        </div>
    );
};

export default AddCatUserTable;
