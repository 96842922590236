
// import { DataGrid } from "@mui/x-data-grid";
// import { useEffect, useState } from "react";
// import { BookColmData } from "../../datatablesource"; // Assuming this contains your column definitions
// import '../UserDetailsViewpg/UserDetails.css';
// import { useLocation, useNavigate } from "react-router-dom";
// import axios from "axios";
// import { ToastContainer, toast } from "react-toastify";
// import { CircularProgress } from "@mui/material";
// import Sidebar from "../../components/sidebar/Sidebar";
// import Navbar from "../../components/navbar/Navbar";

// const AddHomeCatgoryBanner = ({ searchQuery }) => {
  
//   const [bannerStatus, setBannerStatus] = useState({}); // State to hold banner status with banner _id
//   const [isLoading, setIsLoading] = useState(false); // Loading state
//   const navigate = useNavigate();
//   const location = useLocation();
//   const { dataa } = location.state || {}; // Extract data from location.state
//   const [data, setData] = useState([]); // State to hold book data
//   useEffect(() => {
//     window.scrollTo(0, 0);
//   }, []);
//   console.log('Data from location.state:', dataa);

//   // Function to fetch all books from the server
//   // const fetchData = async () => {
//   //   setIsLoading(true);
//   //   try {
//   //     const response = await axios.get('https://new-app-testing-2d30280db142.herokuapp.com/books/get-book');
//   //     const formattedData = response.data.books.reverse().map((item) => ({
//   //       ...item,
//   //       id: item._id, // Ensure each item has a unique `id`
//   //       bannerAdded: false // Initial assumption, adjust as needed
//   //     }));
//   //     // setData(formattedData);
//   //   } catch (error) {
//   //     console.error('Error fetching data:', error);
//   //     toast.error('Failed to fetch books. Please try again.');
//   //   } finally {
//   //     setIsLoading(false);
//   //   }
//   // };

//   // // Function to fetch added banners status from the server
//   // const fetchAddedBanners = async () => {
//   //   try {
//   //     const response = await axios.get('https://new-app-testing-2d30280db142.herokuapp.com/api/get-all-banners');
//   //     const bannerStatusMap = {};
//   //     response.data.bannerBooks.forEach(banner => {
//   //       bannerStatusMap[banner.bookId._id] = banner._id; // Store the banner _id keyed by the bookId
//   //     });
//   //     setBannerStatus(bannerStatusMap);
//   //   } catch (error) {
//   //     console.error('Error fetching added banners:', error);
//   //     toast.error('Failed to fetch added banners. Please try again.');
//   //   }
//   // };

//   // // Fetch data on component mount
//   // useEffect(() => {
//   //   if (dataa) {
//   //     const formattedData = dataa.map((item) => ({
//   //       ...item,
//   //       id: item._id, // Ensure each item has a unique `id`
//   //       bannerAdded: false // Initial assumption, adjust as needed
//   //     }));
//   //     setData(formattedData);
//   //   } else {
//   //     fetchData(); // Fetch data if not passed via location.state
//   //   }
//   // }, [dataa]);

//   // // Update banner status when data changes
//   // useEffect(() => {
//   //   if (data.length > 0) {
//   //     fetchAddedBanners();
//   //   }
//   // }, [data]);

//   // Function to update local banner status
//   // const updateBannerStatus = (bookId, bannerId) => {
//   //   setBannerStatus(prevStatus => ({
//   //     ...prevStatus,
//   //     [bookId]: bannerId
//   //   }));
//   // };

//   // const handleBannerAction = async (bookId) => {
//   //   try {
//   //     const bannerId = bannerStatus[bookId];
//   //     if (bannerId) {
//   //       // If banner is already added, send a delete request
//   //       const response = await axios.delete(`https://new-app-testing-2d30280db142.herokuapp.com/api/delete-banner-book/${bannerId}`);
//   //       if (response.data.success) {
//   //         toast.success('Banner deleted successfully');
//   //         updateBannerStatus(bookId, null); // Update local state after successful deletion
//   //         window.location.reload();
//   //       } else {
//   //         toast.success('Banner remove successfully');
//   //         window.location.reload();
//   //       }
//   //     } else {
//   //       // If banner is not added, send a post request to add
//   //       const response = await axios.post('https://new-app-testing-2d30280db142.herokuapp.com/api/create-banner', { bookId });
//   //       if (response.data.success) {
//   //         toast.success('Banner added successfully');
//   //         updateBannerStatus(bookId, response.data.bannerId); // Update local state after successful addition
//   //         window.location.reload();
//   //       } else {
//   //         toast.error('Failed to add banner');
//   //       }
//   //     }
//   //   } catch (error) {
//   //     console.error('Error updating banner status:', error);
//   //     toast.error('An error occurred while updating banner status');
//   //   }
//   // };
//   const handleBannerAction = async (bookId, currentBannerStatus) => {
//     try {
//       const token = localStorage.getItem('maintoken');
//       const config = {
//         headers: { Authorization: `Bearer ${token}` }
//       };
      
//       const newStatus = currentBannerStatus ? 'false' : 'true';
      
//       const response = await axios.post('https://backend.mysecretlit.com/api/makeHomeBanner', {
//         status: newStatus,
//         bookId: bookId
//       }, config);
  
//       if (response.data.success) {
//         toast.success(`Banner ${currentBannerStatus ? 'removed' : 'added'} successfully`);
//       }
     
//     } catch (error) {
//       console.error('Error updating banner status:', error);
//       toast.error('Failed to update banner status');
//     }
//   };
  
//   // Sorting the data based on banner status (true banners at the top)
//   const sortedData = [...data].sort((a, b) => {
//     const aBanner = bannerStatus[a.id];
//     const bBanner = bannerStatus[b.id];
//     if (aBanner && !bBanner) return -1;
//     if (!aBanner && bBanner) return 1;
//     return 0;
//   });
  
//   // Column definition for DataGrid with action buttons
//   const actionColumn = [
//     {
//       field: "action",
//       headerName: "Action",
//       width: 200,
//       renderCell: (params) => {
//         const isBannerAdded = !!bannerStatus[params.row.id];
//         return (
//           <div className="cellAction">
//             <div
//               onClick={() => handleBannerAction(params.row.id, isBannerAdded)}
//               className={`viewButton ${isBannerAdded ? 'bannerAdded' : ''}`}
//             >
//               {isBannerAdded ? 'Delete Book Banner' : 'Add Banner Category'}
//             </div>
//           </div>
//         );
//       },
//     },
//   ];
  

//   // Render the DataGrid component with books data and action columns
//   return (
//     <div className='list'>
//     <Sidebar />
//     <div className="listContainer">
//         <Navbar headingmain="All Home Page Categories Render Book" showSearchBar={false} />

//         <div className="datatable" style={{ paddingTop: 40 }}>
//       <ToastContainer />
//       {isLoading ? (
//         <div className="loadingSpinner">
//           <CircularProgress color="error" />
//         </div>
//       ) : (
//         <DataGrid
//           className="datagrid"
//           rows={sortedData.map(book => ({
//             ...book,
//             bannerAdded: !!bannerStatus[book.id]
//           }))}
//           columns={BookColmData.concat(actionColumn)}
//           pageSize={100}
//           rowsPerPageOptions={[30]}
//         />
//       )}
//         </div>
//             </div>
//         </div>
//   );
// };

// export default AddHomeCatgoryBanner;
// import { DataGrid } from "@mui/x-data-grid";
// import { useEffect, useState } from "react";
// import { BookColmData } from "../../datatablesource"; // Assuming this contains your column definitions
// import '../UserDetailsViewpg/UserDetails.css';
// import { useLocation } from "react-router-dom";
// import { ToastContainer, toast } from "react-toastify";
// import { CircularProgress } from "@mui/material";
// import Sidebar from "../../components/sidebar/Sidebar";
// import Navbar from "../../components/navbar/Navbar";
// import axios from "axios";

// const AddHomeCatgoryBanner = () => {
//   const [bannerStatus, setBannerStatus] = useState({}); // State to hold banner status with banner _id
//   const [isLoading, setIsLoading] = useState(false); // Loading state
//   const location = useLocation();
//   const { dataa } = location.state || {}; // Extract data from location.state
//   const [data, setData] = useState([]); // State to hold book data
  
//   useEffect(() => {
//     window.scrollTo(0, 0);
//     if (dataa) {
//       // Format and set the data if it's passed via location.state
//       const formattedData = dataa.map((item) => ({
//         ...item,
//         id: item._id, // Ensure each item has a unique `id`
//         bannerAdded: item.homeBanner || false, // Initialize banner status
//       }));
//       setData(formattedData);
//     }
//   }, [dataa]);

//   const handleBannerAction = async (bookId, currentBannerStatus) => {
//     try {
//       const token = localStorage.getItem('maintoken');
//       const config = {
//         headers: { Authorization: `Bearer ${token}` }
//       };
      
//       const newStatus = currentBannerStatus ? 'false' : 'true';
      
//       const response = await axios.post('https://backend.mysecretlit.com/api/makeHomeBanner', {
//         status: newStatus,
//         bookId: bookId
//       }, config);
  
//       if (response.data.success) {
//         toast.success(`Banner ${currentBannerStatus ? 'removed' : 'added'} successfully`);
//         // Update the local banner status
//         setData(prevData =>
//           prevData.map(book =>
//             book.id === bookId ? { ...book, homeBanner: !currentBannerStatus } : book
//           )
//         );
//       } else {
//         toast.error('Failed to update banner status');
//       }
//     } catch (error) {
//       console.error('Error updating banner status:', error);
//       toast.error('Failed to update banner status');
//     }
//   };

//   // Sorting the data based on banner status (true banners at the top)
//   const sortedData = [...data].sort((a, b) => {
//     return a.homeBanner === b.homeBanner ? 0 : a.homeBanner ? -1 : 1;
//   });
  
//   // Column definition for DataGrid with action buttons
//   const actionColumn = [
//     {
//       field: "action",
//       headerName: "Action",
//       width: 200,
//       renderCell: (params) => {
//         const isBannerAdded = !!params.row.homeBanner;
//         return (
//           <div className="cellAction">
//             <div
//               onClick={() => handleBannerAction(params.row.id, isBannerAdded)}
//               className={`viewButton ${isBannerAdded ? 'bannerAdded' : ''}`}
//             >
//               {isBannerAdded ? 'Delete Book Banner' : 'Add Banner Category'}
//             </div>
//           </div>
//         );
//       },
//     },
//   ];

//   // Render the DataGrid component with books data and action columns
//   return (
//     <div className='list'>
//       <Sidebar />
//       <div className="listContainer">
//         <Navbar headingmain="All Home Page Categories Render Book" showSearchBar={false} />
//         <div className="datatable" style={{ paddingTop: 40 }}>
//           <ToastContainer />
//           {isLoading ? (
//             <div className="loadingSpinner">
//               <CircularProgress color="error" />
//             </div>
//           ) : (
//             <DataGrid
//               className="datagrid"
//               rows={sortedData}
//               columns={BookColmData.concat(actionColumn)}
//               pageSize={100}
//               rowsPerPageOptions={[30]}
//             />
//           )}
//         </div>
//       </div>
//     </div>
//   );
// };

// export default AddHomeCatgoryBanner;

import { DataGrid } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { BookColmData } from "../../datatablesource";
import '../UserDetailsViewpg/UserDetails.css';
import { useLocation, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { CircularProgress } from "@mui/material";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import axios from "axios";

const AddHomeCatgoryBanner = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { dataa } = location.state || {}; // Extract data from location.state
  const [data, setData] = useState([]); // State to hold book data

  // Function to format and set the data
  const formatAndSetData = (incomingData) => {
    const formattedData = incomingData.map((item) => ({
      ...item,
      id: item._id, // Ensure each item has a unique `id`
      homeBanner: item.homeBanner || false, // Initialize banner status
    }));
    setData(formattedData);
  };

  // UseEffect to set the initial data from props when component mounts
  useEffect(() => {
    if (dataa) {
      formatAndSetData(dataa);
    }
  }, [dataa]);

  // Function to handle banner action (add/remove)
  // const handleBannerAction = (bookId, currentBannerStatus) => {
  //   const updatedData = data.map((book) =>
  //     book.id === bookId ? { ...book, homeBanner: !currentBannerStatus } : book
  //   );

  //   // Immediately update the state to reflect the changes in UI
  //   setData(updatedData);

  //   // Show success notification
  //   toast.success(`Banner ${currentBannerStatus ? 'removed' : 'added'} successfully`);
  // };
  const handleBannerAction = async (bookId, currentBannerStatus) => {
    // Temporarily update the state to reflect the changes in the UI
    const updatedData = data.map((book) =>
      book.id === bookId ? { ...book, homeBanner: !currentBannerStatus } : book
    );
    
    // Update the state
    setData(updatedData);
  
    // Determine the new banner status
    const bannerStatus = !currentBannerStatus ? 'true' : 'false';
  
    try {
      // Retrieve the token from localStorage
      const token = localStorage.getItem('maintoken');
      
      // Set up the headers with the token
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
  
      // Send the API request to update the banner status
      const response = await axios.post('https://backend.mysecretlit.com/api/makeHomeBanner', {
        status: bannerStatus,
        bookId: bookId,
      }, config);
  
      // Check if the response was successful
      if (response.data && response.data.success) {
        toast.success(`Banner ${bannerStatus === 'true' ? 'added' : 'removed'} successfully`);
      } else {
        toast.success(`Banner ${bannerStatus === 'true' ? 'added' : 'removed'} successfully`);
        // Revert the state change if the API call was unsuccessful
        // setData(data);
      }
    } catch (error) {
      console.error('Error updating banner status:', error);
      toast.error('Failed to update banner status');
      // Revert the state change if an error occurred
      // setData(data);
    }
  };
  

  // Sorting the data based on banner status (true banners at the top)
  const sortedData = [...data].sort((a, b) => {
    return a.homeBanner === b.homeBanner ? 0 : a.homeBanner ? -1 : 1;
  });

  // Column definition for DataGrid with action buttons
  const actionColumn = [
    {
      field: "action",
      headerName: "Action",
      width: 200,
      renderCell: (params) => {
        const isBannerAdded = !!params.row.homeBanner;
        return (
          <div className="cellAction">
            <div
              onClick={() => handleBannerAction(params.row.id, isBannerAdded)}
              className={`viewButton ${isBannerAdded ? 'bannerAdded' : ''}`}
            >
              {isBannerAdded ? 'Delete Banner Category' : 'Add Banner Category'}
            </div>
          </div>
        );
      },
    },
  ];

  // Render the DataGrid component with books data and action columns
  return (
    <div className='list'>
      <Sidebar />
      <div className="listContainer">
        <Navbar headingmain="All Home Page Categories Render Book" showSearchBar={false} />
        <div className="datatable" style={{ paddingTop: 40 }}>
          <ToastContainer />
          {data.length === 0 ? (
            <div className="loadingSpinner">
              <CircularProgress color="error" />
            </div>
          ) : (
            <DataGrid
              className="datagrid"
              rows={sortedData}
              columns={BookColmData.concat(actionColumn)}
              pageSize={100}
              rowsPerPageOptions={[30]}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default AddHomeCatgoryBanner;
