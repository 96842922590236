import React, { useEffect, useState } from 'react'
import {  ContactPage,  } from '../../datatablesource';
import { useNavigate } from 'react-router-dom';
import Sidebar from '../../components/sidebar/Sidebar';
import { DataGrid } from '@mui/x-data-grid';
import "../list/list.scss"
import '../UserDetailsViewpg/UserDetails.css'
import Navbar from '../../components/navbar/Navbar';
import { ToastContainer, toast } from "react-toastify";
import { CircularProgress } from '@mui/material';
import axios from 'axios';

const ConatactPage = () => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const navigation = useNavigate();
  const token = localStorage.getItem('maintoken'); // Retrieve token from local storage

  const fetchData = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get('https://backend.mysecretlit.com/api/getcontactus',{
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const formattedData = response.data.message.reverse().map((item, index) => ({
        ...item,
        id: item._id
      }));
      setData(formattedData);
    } catch (error) {
      console.error('Error fetching data:', error);
      toast.error('Failed to fetch books. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);
  
    const handleDelete = async(id) => {
      // setData(data.filter((item) => item.id !== id));
      try {
        const res = await axios.delete(`https://new-app-testing-2d30280db142.herokuapp.com/api/single-delete-contact/${id}`);
        if (res.status === 200) {
          setData(data.filter((item) => item.id !== id));
          toast.success('message successfully delete')
        } else {
          console.error('Failed to delete user. Please try again.');
        }
      } catch (error) {
        console.error('Error making API call:', error);
      }
    };
    const handleViewDetails = (params) => {
      navigation('/users/contact-details', { state: { data: params } });
    };
    const actionColumn = [
        {
          field: "action",
          headerName: "Action",
          width: 150,
          renderCell: (params) => {
            return (
              <div className="cellAction">
       
                  <div onClick={()=>handleViewDetails(params.row)} className="viewButton">View</div>
                <div
                  className="deleteButton"
                  onClick={() => handleDelete(params.row.id)}
                >
                  Delete
                </div>
              </div>
            );
          },
        },
      ];
  return (
    <div className='list'>
    <Sidebar/>
       <div className="listContainer">
           
       <Navbar headingmain='Contact Us' showSearchBar={true} />
       <div className="datatable">
       <ToastContainer/>
      {isLoading ? (
        <div className="loadingSpinner">
          <CircularProgress color="error" />
        </div>
      ) : (
      <DataGrid
        className="datagrid"
        rows={data}
        columns={ContactPage.concat(actionColumn)}
        pageSize={100}
        rowsPerPageOptions={[9]}
        checkboxSelection
      />)}
    </div>
       </div>
       </div>
  )
}

export default ConatactPage