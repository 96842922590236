import { DataGrid } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import axios from "axios";
import CircularProgress from "@mui/material/CircularProgress";
import { ToastContainer, toast } from "react-toastify";
import Checkbox from "@mui/material/Checkbox";
import { allCategory } from "../../datatablesource"; // Assuming you have imported `allCategory` from your source file

const AllCategoryTable = ({ searchQuery }) => {
  const [data, setData] = useState([]);
  const [homeCategories, setHomeCategories] = useState([]); // Initialize homeCategories
  const [isLoading, setIsLoading] = useState(false);

  const handleDelete = async (id) => {
    setData(data.filter((item) => item.id !== id)); // Update local state
    console.log('delete id', id);
    try {
      await axios.delete(`https://backend.mysecretlit.com/api/book/deletecategory/${id}`);
      toast.success('This category is successfully deleted!');
    } catch (error) {
      console.error('Error making API call:', error);
      if (error.response) {
        toast.error(error.response.data.message || 'Error deleting category. Please try again.');
      } else if (error.request) {
        toast.error('No response from server. Please try again.');
      } else {
        toast.error('Error deleting category. Please try again.');
      }
    }
  };

  const handleCategorySelect = async (id, status) => {
    try {
      await axios.patch(`https://backend.mysecretlit.com/api/book/selectCategory/${id}`, { status });
      // Update homeCategories state after patch
      const updatedHomeCategories = homeCategories.map((cat) =>
        cat.id === id ? { ...cat, status } : cat
      );
      setHomeCategories(updatedHomeCategories);
      window.location.reload()
    } catch (error) {
      console.error('Error updating category status:', error);
    }
  };

  const fetchHomecateg = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get('https://backend.mysecretlit.com/api/user/getHomeGenre');
      // Ensure response.data is an array
      if (Array.isArray(response.data.data)) {
        setHomeCategories(response.data.data);
      } else {
        console.error('Unexpected data format:', response.data);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchData = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get('https://backend.mysecretlit.com/api/user/getGenres?type=test');
      const formattedData = response.data.data.reverse().map((item) => ({
        ...item,
        id: item._id,
      }));
      setData(formattedData);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const filteredData = data.filter((item) =>
    item.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  useEffect(() => {
    fetchData();
    fetchHomecateg();
  }, []);

  const renderCell = (params) => {
    if (Array.isArray(homeCategories)) {
      const isChecked = homeCategories.some((category) => category.id === params.row.id);
      return (
        <Checkbox
          checked={isChecked}
          onChange={() => handleCategorySelect(params.row.id, !isChecked)}
        />
      );
    } else {
      console.error('homeCategories is not an array:', homeCategories);
      return null;
    }
  };

  const actionColumn = [
    {
      field: "action",
      headerName: "Action",
      width: 150,
      renderCell: (params) => (
        <div className="cellAction">
          <div
            className="deleteButton"
            onClick={() => handleDelete(params.row.id)}
          >
            Delete
          </div>
        </div>
      ),
    },
    {
      field: "select",
      headerName: "Home Genres Category",
      width: 200,
      renderCell: renderCell, // Use the renderCell function for the checkbox
    },
  ];

  return (
    <div className="datatable" style={{ paddingTop: 0 }}>
      <div className="datatableTitle">All Categories Data</div>
      <ToastContainer />
      {isLoading ? (
        <div className="loadingSpinner">
          <CircularProgress color="error" />
        </div>
      ) : (
        data?.length > 0 ? (
          <DataGrid
            className="datagrid"
            rows={filteredData}
            columns={allCategory?.concat(actionColumn)}
            pageSize={25}
            rowsPerPageOptions={[9]}
            // checkboxSelection
          />
        ) : (
          <p>No data available</p>
        )
      )}
    </div>
  );
};

export default AllCategoryTable;
