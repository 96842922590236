
import { DataGrid } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
import CircularProgress from "@mui/material/CircularProgress";
import { userColumns } from "../../datatablesource";
import { ToastContainer, toast } from "react-toastify";

const SuspendedUser = ({searchQuery}) => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const navigation = useNavigate();

  const fetchData = async () => {
    const token = localStorage.getItem('maintoken');
    setIsLoading(true);
    try {
      const response = await axios.get('https://backend.mysecretlit.com/api/getAllUsers',{
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const formattedData = response.data?.data
        .filter(item => item.isSuspended) // Exclude objects where isSuspended is true
        .map((item) => ({
          ...item,
          id: item._id,
        }));
  
      setData(formattedData);
      console.log("Filtered user data", formattedData);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleDelete = async (id) => {
    const token = localStorage.getItem('maintoken');

    try {
      const res = await axios.post('https://backend.mysecretlit.com/api/suspendUser',{status:'false',userId:id},{
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (res.status === 200) {
        setData(data.filter((item) => item.id !== id));
        toast.success('user unsuspended sucessfully')
      } else {
        console.error('Failed to delete user. Please try again.');
      }
    } catch (error) {
      console.error('Error making API call:', error);
    }
  };

  const handleViewDetails = (params) => {
        navigation('/users/view-details/', { state: { data: params } });
      };

  const filteredData = data.filter(user =>
    user.firstName.toLowerCase().includes(searchQuery.toLowerCase()) ||
    user.lastLame.toLowerCase().includes(searchQuery.toLowerCase()) ||
    user.email.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const actionColumn = {
    field: "action",
    headerName: "Action",
    width: 200,
    renderCell: (params) => {
      return (
        <div className="cellAction">
          <div onClick={() => handleViewDetails(params.row)} className="viewButton">View</div>
          <div className="deleteButton" onClick={() => handleDelete(params.row.id)}>Unsuspend</div>
        </div>
      );
    },
  };

  return (
    <div className="datatable">
        <ToastContainer/>
      {isLoading ? (
        <div className="loadingSpinner">
          <CircularProgress color="error" />
        </div>
      ) : (  
          <DataGrid
            className="datagrid"
            rows={filteredData}
            columns={[...userColumns, actionColumn]}
            pageSize={100}
            rowsPerPageOptions={[10, 15, 20,170]}
          />
      
      )}
    </div>
  );
};

export default SuspendedUser;
