import React, { useEffect, useState } from 'react';
import Navbar from '../../components/navbar/Navbar';
import "../list/list.scss";
import './UserDetails.css';
import Sidebar from '../../components/sidebar/Sidebar';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import moment from 'moment-timezone';

const UserDetailsVIewPg = () => {
    const location = useLocation();
    const [plandata,setplandata] = useState()
    const { data } = location.state;
    console.log('dasdasd',data)
    const [areaName, setAreaName] = useState('');
    const originalDate = data?.createdAt;
    const formattedDate = moment.tz(originalDate, 'America/New_York').format('MMMM Do YYYY, h:mm:ss a'); // Change the timezone as needed
    useEffect(() => {
        if (data?.location?.coordinates) {
            const [longitude, latitude] = data.location.coordinates;
            const getAreaNameFromCoordinates = async () => {
                const apiKey = 'AIzaSyB_nNvYWSCB2haI7DCgR6chQmsg-T4oj8s'; // Replace with your Google Maps API key
                const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${apiKey}`;

                try {
                    const response = await axios.get(url);
                    const data = response.data;
                    
                    if (data.status === 'OK') {
                        const address = data.results[0].formatted_address;
                        setAreaName(address);
                    } else {
                        console.error('Error in reverse geocoding:', data.status);
                        setAreaName('Unable to get the location');
                    }
                } catch (error) {
                    console.error('Error fetching data:', error);
                    setAreaName('Error fetching data');
                }
            };

            getAreaNameFromCoordinates();
        }
    }, [data]);

    const fetchData = async () => {
       
        try {
          const response = await axios.get(`https://new-app-testing-2d30280db142.herokuapp.com/api/payments/get-all-remaning-dowloads/${data._id}`);
        setplandata(response?.data?.currentPlan)
        console.log(response?.data)
        } catch (error) {
          
        } 
      };
useEffect(()=>{
    fetchData()
},[])
    return (
        <div className='list'>
            <Sidebar />
            <div className="listContainer">
                <Navbar headingmain='Complete User Details' />
                <div className='all-book-style'>
                    <div style={{ marginTop: '10px' }}>
                        <div className="profileImg">
                            <img style={{ width: '10em', height: '10em', borderRadius: '50%' }} src={data?.img || 'https://images.theconversation.com/files/45159/original/rptgtpxd-1396254731.jpg?ixlib=rb-4.1.0&q=45&auto=format&w=1356&h=668&fit=crop'} alt="" />
                            <div className="userData-details">
                                <div className="name-tag">
                                    <label>Name:</label>
                                    <p>{data.firstName} <span style={{ textTransform: "capitalize" }}>{data.lastName}</span></p>
                                </div>
                                <div className="name-tag">
                                    <label>Email:</label>
                                    <p>{data.email}</p>
                                </div>
                                <div className="name-tag">
                                    <label>Gender:</label>
                                    <p>{data.gender}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row-userDetails">
                        <div className="input-feild-label">
                            <p className="paragraph-details-user">First Name</p>
                            <input value={data.firstName} className='inputfeild-userDetails' placeholder='First name' />
                        </div>
                        <div className="input-feild-label">
                            <p className="paragraph-details-user">Last Name</p>
                            <input value={data.lastName} className='inputfeild-userDetails' placeholder='Last name' />
                        </div>
                    </div>
                    <div className="row-userDetails" style={{ marginTop: '1em' }}>
                        <div className="input-feild-label">
                            <p className="paragraph-details-user">Joining Date</p>
                            <input value={formattedDate} className='inputfeild-userDetails' placeholder='Joining Date' />
                        </div>
                        <div className="input-feild-label">
                            <p className="paragraph-details-user">Gender</p>
                            <input value={data.gender} className='inputfeild-userDetails' placeholder='Gender' />
                        </div>
                    </div>
                    <div className="row-userDetails" style={{marginTop:15}}>
                        <div className="input-feild-label">
                            <p className="paragraph-details-user">Books Downloads Available</p>
                            <input value={plandata?.bookDownload ||'no buy plan'} className='inputfeild-userDetails' placeholder='First name' />
                        </div>
                        <div className="input-feild-label">
                            <p className="paragraph-details-user">AudioBooks Downloads Available</p>
                            <input value={plandata?.audioBookDownload||'no buy plan'} className='inputfeild-userDetails' placeholder='Last name' />
                        </div>
                    </div>
                    <div className="input-feild-label" style={{ marginTop: '1em' }}>
                        <p className="paragraph-details-user">Email Address</p>
                        <input value={data.email} className='inputfeild-userDetails' placeholder='Email Address' />
                    </div>
                    <div className="input-feild-label" style={{ marginTop: '1em' }}>
                        <p className="paragraph-details-user">Location</p>
                        <input value={areaName} className='inputfeild-userDetails' placeholder='Location' />
                    </div>
                    <div className="input-feild-label" style={{ marginTop: '1em' }}>
                        <p className="paragraph-details-user">Payment Plans</p>
                        <input value={plandata?.paymentPlan || 'no buy plan'} className='inputfeild-userDetails' placeholder='Payment Plans' />
                    </div>
                    {/* <div className="input-feild-label" style={{ marginTop: '1em' }}>
                        <p className="paragraph-details-user">User Messages</p>
                        <textarea value={data.usermessage} rows="4" className='inputfeild-userDetails' placeholder='User Messages' />
                    </div> */}
                </div>
            </div>
        </div>
    );
};

export default UserDetailsVIewPg;
