import { DataGrid } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { BookColmData, BookData, userColumns,userRows } from "../../datatablesource";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { CircularProgress } from "@mui/material";

const AllSeriesBook = ({searchQuery}) => {

  const [data, setData] = useState([]);
  const navigation = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  // const handleDelete = (id) => {
  //   setData(data.filter((item) => item.id !== id));
  // };
  // const handleViewDetails = (params) => {
  //   navigation('/all-books/book-details', { state: { data: params } });
  // };
  const fetchData = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get('https://backend.mysecretlit.com/api/user/getSeries');
      const formattedData = response.data.data.reverse().map((item, index) => ({
        ...item,
        id: item._id
      }));
      setData(formattedData);
    } catch (error) {
      console.error('Error fetching data:', error);
      toast.error('Failed to fetch books. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(()=>{
    fetchData()
  },[])
  const handleDelete = async (id) => {
    try {
      const res = await axios.delete(`https://backend.mysecretlit.com/api/book/deleteBook/${id}`);
      if (res.status === 200) {
        setData(data.filter((item) => item.id !== id));
        toast.success('Book deleted successfully!');
      } else {
        toast.error('Failed to delete book. Please try again.');
      }
    } catch (error) {
      console.error('Error making API call:', error);
      toast.error('Failed to delete book. Please try again.');
    }
  };

  const handleViewDetails = (params) => {
    navigation('/all-books/book-details', { state: { data: params } });
  };

  const actionColumn = [
    {
      field: "action",
      headerName: "Action",
      width: 150,
      renderCell: (params) => {
        return (
          <div className="cellAction">
            <div onClick={() => handleViewDetails(params.row)} className="viewButton">Edit</div>
            <div className="deleteButton" onClick={() => handleDelete(params.row.id)}>Delete</div>
          </div>
        );
      },
    },
  ];

  const filteredData = data.filter(book => 
    book.title.toLowerCase().includes(searchQuery.toLowerCase())
  );
  return (
    <div className="datatable" >
      <ToastContainer/>
      {isLoading ? (
        <div className="loadingSpinner">
          <CircularProgress color="error" />
        </div>
      ) : (
      <DataGrid
        className="datagrid"
        rows={filteredData}
        columns={BookColmData.concat(actionColumn)}
        pageSize={100}
        rowsPerPageOptions={[120]}
        checkboxSelection
      />)}
    </div>
  );
};

export default AllSeriesBook;
