import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import "../list/list.scss";
import '../UserDetailsViewpg/UserDetails.css';
import Sidebar from '../../components/sidebar/Sidebar';
import Navbar from '../../components/navbar/Navbar';
import { Button, Spinner } from 'react-bootstrap';
import axios from 'axios';
import { toast } from 'react-toastify';
import { Autocomplete, Avatar, Box, Rating, TextField, Typography } from '@mui/material';

const DetailsBookTab = () => {
  const location = useLocation();
  const { data } = location.state || {};
  const navigation = useNavigate();
  const [catdata, setcatdata] = useState([]);
  const [seriesdata, setseriesdata] = useState([]);
  const [isSeriesFieldVisible, setIsSeriesFieldVisible] = useState(data?.series || false); // Show series dropdown based on initial series value
  const [formData, setFormData] = useState({
    bookId: data?._id,
    seriesList: data?.seriesList || [],
    title: data?.title || '',
    oblicAuthor: data?.banner || '',
    authorName: data?.authorName || '',
    category: data?.category?._id || '',
    secondaryCategory: data?.secondaryCategory?._id || '',
    series: data?.series || '',
    shortDescription: data?.shortDescription || '',
    triggerWarningText: data?.triggerWarningText || '',
    longDescription: data?.longDescription || '',
    userMessages: data?.userMessages || '',
    pdfUpdate: data?.pdfUrl || '',
    img: data?.coverImage || 'https://www.setantabooks.com/cdn/shop/products/IMG_9322_6e7219ba-55da-43ff-bac1-ba6bc68acb8d.jpg?v=1706971799&width=480',
    epubUpload: data?.epubUrl || '',
    kindleMobiUpload: data?.kindleMobiUrl || '',
    rating: data?.rating || 0,
  });

  const [loading, setLoading] = useState(false);

  // Fetch categories
  const fetchCategories = async () => {
    try {
      const response = await axios.get('https://backend.mysecretlit.com/api/user/getGenres?type=test');
      const formattedData = response.data?.data.map((item) => ({
        ...item,
        id: item._id
      }));
      setcatdata(formattedData);
    } catch (error) {
      console.error('Error fetching categories:', error);
    }
  };

  // Fetch series data
  const fetchSeries = async () => {
    try {
      const response = await axios.get('https://backend.mysecretlit.com/api/user/getSeries');
      const formattedData = response.data.data.reverse().map((item, index) => ({
        ...item,
        id: item._id
      }));
      setseriesdata(formattedData);
    } catch (error) {
      console.error('Error fetching series data:', error);
      toast.error('Failed to fetch series. Please try again.');
    }
  };

  useEffect(() => {
    fetchCategories();
    fetchSeries();
  }, []);

  // Handle input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // Handle series change
  const handleSeriesChange = (e) => {
    const { value } = e.target;
    setFormData({ ...formData, series: value === 'Yes' });
    setIsSeriesFieldVisible(value === 'Yes');
  };

  // Handle selecting a book from the series autocomplete
  const handleBookSelect = (event, newValue) => {
    if (newValue) {
      setFormData({ ...formData, seriesList: [newValue.id] }); // Update series list in formData
    } else {
      setFormData({ ...formData, seriesList: [] }); // Clear seriesList if no value is selected
    }
  };

  // Handle deleting a book
  const handleDelete = async () => {
    setLoading(true);
    try {
      const res = await axios.delete(`https://backend.mysecretlit.com/api/book/deleteBook/${data?._id}`);
      if (res.status === 200) {
        toast.success('Book deleted successfully!');
        navigation('/all-books');
      } else {
        toast.error('Failed to delete book. Please try again.');
      }
    } catch (error) {
      console.error('Error deleting book:', error);
      toast.error('Failed to delete book. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  // File upload helper function
  const uploadFile = async (file, type) => {
    try {
      const formData = new FormData();
      formData.append("file", file);

      const response = await axios.post('https://backend.mysecretlit.com/api/uploadFile', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      return response.data.data.path;
    } catch (error) {
      console.error(`Error uploading ${type}:`, error);
      toast.error(`Error uploading ${type}. Please try again.`);
      return "";
    }
  };

  // Handle updating book details
  const handleUpdate = async () => {
    let bookCoverImageUrl = "";
    let pdfUrl = "";
    let epubUrl = "";
    let mobiUrl = "";
    const token = localStorage.getItem('maintoken');

    // Upload files if they are new
    if (formData.img instanceof File) bookCoverImageUrl = await uploadFile(formData.img, "image");
    if (formData.pdfUpdate instanceof File) pdfUrl = await uploadFile(formData.pdfUpdate, "PDF");
    if (formData.epubUpload instanceof File) epubUrl = await uploadFile(formData.epubUpload, "EPUB");
    if (formData.kindleMobiUpload instanceof File) mobiUrl = await uploadFile(formData.kindleMobiUpload, "MOBI");

    setLoading(true);

    // Prepare payload
    const payload = {
      bookId: formData.bookId,
      title: formData.title,
      banner: formData.oblicAuthor,
      authorName: formData.authorName,
      category: formData.category,
      secondaryCategory: formData.secondaryCategory,
      series: formData.series,
      shortDescription: formData.shortDescription,
      triggerWarningText: formData.triggerWarningText,
      longDescription: formData.longDescription,
      rating: formData.rating,
      coverImage: bookCoverImageUrl || formData.img,
      pdfUrl: pdfUrl || formData.pdfUpdate,
      epubUrl: epubUrl || formData.epubUpload,
      kindleMobiUrl: mobiUrl || formData.kindleMobiUpload,
      seriesList: formData.seriesList // Add series list to payload
    };

    try {
      const response = await axios.post('https://backend.mysecretlit.com/api/updateBook', payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 200) {
        toast.success('Book details updated successfully!');
        navigation('/all-books');
      } else {
        toast.error('Failed to update book details. Please try again.');
      }
    } catch (error) {
      console.error('Error updating book details:', error);
      toast.error('Failed to update book details. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  // Handle image and file changes
  const handleImageChange = (e) => {
    const selectedImage = e.target.files[0];
    setFormData({ ...formData, img: selectedImage });
  };

  const handleFileChange = (e, fileType) => {
    const file = e.target.files[0];
    setFormData({ ...formData, [fileType]: file });
  };

  return (
    <div className='list'>
      <Sidebar />
      <div className="listContainer">
        <Navbar headingmain={`${formData.title} Book Details`} />
        <div className='all-book-style'>
          {loading && (
            <div className="spinner-overlay">
              <Spinner animation="border" variant="danger" />
            </div>
          )}
          <div style={{ marginTop: '10px' }}>
            <div className="profileImg">
              <img style={{ width: '10em', height: '10em', borderRadius: '50%' }} src={typeof formData.img === 'string' ? formData.img : URL.createObjectURL(formData.img)} alt="" />
              <div className="userData-details" style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div className="name-tag">
                  <label>Name:</label>
                  <p>{formData.title}</p>
                </div>
                <div className="btn-froth">
                  <Button style={{ width: '10em', background: 'white', borderColor: '#ee2d2f', color: '#ee2d2f' }} onClick={handleUpdate} disabled={loading}>Update</Button>
                  <Button style={{ width: '10em', background: '#ee2d2f', borderColor: '#ee2d2f' }} onClick={handleDelete} disabled={loading}>Delete</Button>
                </div>
              </div>
            </div>
          </div>
          {/* Form fields */}
          <div className="row-userDetails">
            <div className="input-feild-label">
              <p className="paragraph-details-user">Book Title</p>
              <input value={formData.title} onChange={(e) => setFormData({ ...formData, title: e.target.value })} className='inputfeild-userDetails' placeholder='Book Title' />
            </div>
            <div className="input-feild-label">
              <p className="paragraph-details-user">Book Title/Oblic Author</p>
              <input value={formData.oblicAuthor} onChange={(e) => setFormData({ ...formData, oblicAuthor: e.target.value })} className='inputfeild-userDetails' placeholder='Oblic Author' />
            </div>
          </div>
          <div className="row-userDetails" style={{ marginTop: '1em' }}>
            <div className="input-feild-label">
              <p className="paragraph-details-user">Author Name</p>
              <input value={formData.authorName} onChange={(e) => setFormData({ ...formData, authorName: e.target.value })} className='inputfeild-userDetails' placeholder='Author Name' />
            </div>
            <div className="input-feild-label">
              <p className="paragraph-details-user">Primary Category</p>
              <select id='cars' name="category" value={formData.category} onChange={handleInputChange} required>
                {catdata.map((category) => (
                  <option key={category.id} value={category.id}>{category.name}</option>
                ))}
              </select>
            </div>
          </div>
          <div className="row-userDetails" style={{ marginTop: '1em' }}>
            <div className="input-feild-label">
              <p className="paragraph-details-user">Secondary Category</p>
              <select id='cars' name="secondaryCategory" value={formData.secondaryCategory} onChange={handleInputChange} required>
                {catdata.map((category) => (
                  <option key={category.id} value={category.id}>{category.name}</option>
                ))}
              </select>
            </div>
            <div className="input-feild-label">
              <p className="paragraph-details-user">Series</p>
              <select id="cars" name="series" value={formData.series ? 'Yes' : 'No'} onChange={handleSeriesChange} required>
                <option value="Yes">Yes</option>
                <option value="No">No</option>
              </select>
            </div>
          </div>
          {isSeriesFieldVisible && (
            <div className="input-feild-label" style={{ marginTop: '1em', width: '98%' }}>
              <p className="paragraph-details-user">Series Name</p>
              <Autocomplete
                options={seriesdata}
                getOptionLabel={(option) => option.title}
                value={seriesdata.find(item => item.id === formData.seriesList[0]) || null} // Auto-select based on seriesList ID
                onChange={handleBookSelect}
                sx={{ width: '51%' }}
                renderInput={(params) => <TextField {...params} />}
                renderOption={(props, option) => (
                  <Box component="li" {...props} display="flex" alignItems="center">
                    <Avatar
                      src={option.coverImage}
                      alt={option.title}
                      sx={{ marginRight: 2 }}
                    />
                    <Box>
                      <Typography variant="body1" fontWeight="bold">
                        {option.title}
                      </Typography>
                      <Typography variant="body2" color="textSecondary">
                        {option.authorName}
                      </Typography>
                    </Box>
                  </Box>
                )}
              />
            </div>
          )}
          {/* Other fields like Short Description, Trigger Warning, etc. */}
          <div className="input-feild-label" style={{ marginTop: '1em' }}>
            <p className="paragraph-details-user">Short Description</p>
            <textarea value={formData.shortDescription} onChange={(e) => setFormData({ ...formData, shortDescription: e.target.value })} rows="3" placeholder="Short Description" className='inputfeild-userDetails' />
          </div>
          <div className="input-feild-label" style={{ marginTop: '1em' }}>
            <p className="paragraph-details-user">Trigger Warning Text</p>
            <textarea value={formData.triggerWarningText} onChange={(e) => setFormData({ ...formData, triggerWarningText: e.target.value })} rows="3" placeholder="Trigger Warning Text" className='inputfeild-userDetails' />
          </div>
          <div className="input-feild-label" style={{ marginTop: '1em' }}>
            <p className="paragraph-details-user">Long Description</p>
            <textarea value={formData.longDescription} onChange={(e) => setFormData({ ...formData, longDescription: e.target.value })} rows="6" placeholder="Long Description" className='inputfeild-userDetails' />
          </div>
          <div className="row-userDetails" style={{ marginTop: '1em' }}>
            <div className="input-feild-label">
              <p className="paragraph-details-user">Rating</p>
              <Rating name="simple-controlled" value={formData.rating} onChange={(event, newValue) => setFormData({ ...formData, rating: newValue })} />
            </div>
          </div>
          {/* Image, PDF, EPUB, and MOBI uploads */}
          <div className="row-userDetails" style={{ marginTop: '1em' }}>
            <div className="input-feild-label">
              <p className="paragraph-details-user">Update Book Cover</p>
              <input type="file" accept="image/*" onChange={handleImageChange} />
              <img src={data?.coverImage} style={{ height: '300px', objectFit: 'cover', width: '300px', marginTop: 10 }} />
            </div>
          </div>
          <div className="" style={{ marginTop: '1em' }}>
            <div className="input-feild-label">
              <p className="paragraph-details-user">Update PDF</p>
              <input type="file" accept=".pdf" onChange={(e) => handleFileChange(e, 'pdfUpdate')} />
              <p>{data?.pdfUrl}</p>
            </div>
          </div>
          <div className="" style={{ marginTop: '1em' }}>
            <div className="input-feild-label">
              <p className="paragraph-details-user">Update EPUB</p>
              <input type="file" accept=".epub" onChange={(e) => handleFileChange(e, 'epubUpload')} />
              <p>{data?.epubUrl}</p>
            </div>
          </div>
          <div className="" style={{ marginTop: '1em' }}>
            <div className="input-feild-label">
              <p className="paragraph-details-user">Update Kindle MOBI</p>
              <input type="file" accept=".mobi" onChange={(e) => handleFileChange(e, 'kindleMobiUpload')} />
              <p>{data?.kindleMobiUrl}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DetailsBookTab;
