import React, { useEffect, useState } from 'react';
import "./widget.scss";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import AccountBalanceWalletOutlinedIcon from "@mui/icons-material/AccountBalanceWalletOutlined";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import MonetizationOnOutlinedIcon from "@mui/icons-material/MonetizationOnOutlined";
import { Link } from 'react-router-dom'; // Import Link from react-router-dom
import axios from 'axios';
const Widget = ({ type }) => {
  const [Data, setData] = useState(false);
  const [suspendeduser,setsuspendeduser] = useState([])
  let data;
  let amount
  // const fetchData = async () => {
  //   try {
  //     const response = await axios.get('https://backend.mysecretlit.com/api/getAllUsers');
  //     const formattedData = response.data.data.map((item, index) => ({
  //       ...item,
  //       id: item._id
  //     }));
  //     setData(formattedData);
  //   } catch (error) {
  //   } 
  // };
  // const Suspendeduser = async () => {
  //   try {
  //     const response = await axios.get('https://backend.mysecretlit.com/api/getAllUsers');
  //     const formattedData = response.data?.data
  //     .filter(item => item.isSuspended) // Exclude objects where isSuspended is true
  //     .map((item) => ({
  //       ...item,
  //       id: item._id,
  //     }));
  //     setsuspendeduser(formattedData);
  //   } catch (error) {
  //     console.error('Error fetching data:', error);
  //   } 
  // };
  const fetchData = async () => {
  
      // const [usersResponse] = await Promise.all([
      //   axios.get('https://backend.mysecretlit.com/api/getAllUsers'),
      // ]);
      const token = localStorage.getItem('maintoken');
      try {
        const [usersResponse] = await Promise.all([
          axios.get('https://backend.mysecretlit.com/api/getAllUsers',{
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }),
        ]);
      const allUsers = usersResponse.data.data.map(item => ({
        ...item,
        id: item._id,
      }));

      setData(allUsers);

      const suspendedUsers = allUsers.filter(item => item.isSuspended);
      setsuspendeduser(suspendedUsers);
    } catch (error) {
      console.log('Error fetching data');
    }
  };
  useEffect(() => {
    fetchData();
    // Suspendeduser()
  }, []);
  const diff = 20;

  switch (type) {
    case "user":
      data = {
        title: "USERS",
        isMoney: false,
        link: "/users", // Set the route for users
        icon: (
          <PersonOutlinedIcon
            className="icon"
            style={{
              color: "crimson",
              backgroundColor: "rgba(255, 0, 0, 0.2)",
            }}
          />
        ),
      };
      amount = Data?.length;
      break;
      case "suspenduser":
        data = {
          title: "Suspended Users",
          isMoney: false,
          link: "/Dashboard/all-suspended-users",
          icon: (
            <PersonOutlinedIcon
              className="icon"
              style={{
                color: "crimson",
                backgroundColor: "rgba(255, 0, 0, 0.2)",
              }}
            />
          ),
        };
        amount = suspendeduser.length;
        break;
    case "order":
      data = {
        title: "MEMBERS",
        isMoney: false,
        // link: "/orders", // Set the route for orders if needed
        icon: (
          <ShoppingCartOutlinedIcon
            className="icon"
            style={{
              backgroundColor: "rgba(218, 165, 32, 0.2)",
              color: "goldenrod",
            }}
          />
        ),
      };
      amount = Data?.length;
      break;
      
    case "earning":
      data = {
        title: "EARNINGS",
        isMoney: true,
        // link: "/earnings", // Set the route for earnings if needed
        icon: (
          <MonetizationOnOutlinedIcon
            className="icon"
            style={{ backgroundColor: "rgba(0, 128, 0, 0.2)", color: "green" }}
          />
        ),
      };
      amount = 120;
      break;
    case "balance":
      data = {
        title: "MARKETING EXPENSE",
        isMoney: true,
        // link: "/balance", // Set the route for balance if needed
        icon: (
          <AccountBalanceWalletOutlinedIcon
            className="icon"
            style={{
              backgroundColor: "rgba(128, 0, 128, 0.2)",
              color: "purple",
            }}
          />
        ),
      };
      amount = 1200;
      break;
    
    case "netprofit":
      data = {
        title: "NET PROFIT",
        isMoney: true,
        // link: "/netprofit", // Set the route for net profit if needed
        icon: (
          <AccountBalanceWalletOutlinedIcon
            className="icon"
            style={{
              backgroundColor: "rgba(128, 0, 128, 0.2)",
              color: "purple",
            }}
          />
        ),
      };
      amount = 20;
      break;
    case "geoLocation":
      data = {
        title: "GEO LOCATION",
        isMoney: true,
        link: "/Dashboard/map", // Set the route for geo location
        icon: (
          <AccountBalanceWalletOutlinedIcon
            className="icon"
            style={{
              backgroundColor: "rgba(128, 0, 128, 0.2)",
              color: "purple",
            }}
          />
        ),
      };
      break;
    default:
      break;
  }

  return (
    <div className="widget">
      <div className="left">
        <span className="title">{data.title}</span>
        <span className="counter">
          {data.isMoney && "$"} {amount}
        </span>
        {data.link && (
          <Link style={{textDecoration:'none'}} to={data.link} className="link"> {type === "user" ? "See all users" : type === "geoLocation" ? "See Map" : "See all suspended users"}</Link>
        )}
      </div>
      <div className="right">
        <div className="percentage positive">
          <KeyboardArrowUpIcon />
          {diff} %
        </div>
        {data.icon}
      </div>
    </div>
  );
};

export default Widget;
