import React, { useEffect, useState } from 'react'
import Sidebar from '../../components/sidebar/Sidebar'
import Navbar from '../../components/navbar/Navbar'
import "../list/list.scss";
import '../UserDetailsViewpg/UserDetails.css';
import {  AudioBooksData } from '../../datatablesource'
import { DataGrid } from '@mui/x-data-grid'
import { useNavigate } from 'react-router-dom'
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import { CircularProgress } from '@mui/material';
const AddAudioBooks = () => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const navigation = useNavigate();
  const [searchQuery, setSearchQuery] = useState('');
  const fetchData = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get('https://backend.mysecretlit.com/api/user/getAudioBooks');
      const formattedData = response.data.data.reverse().map((item, index) => ({
        ...item,
        id: item._id
      }));
      setData(formattedData);
    } catch (error) {
      console.error('Error fetching data:', error);
      toast.error('Failed to fetch books. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleDelete = async (id) => {
    try {
      const res = await axios.delete(`https://backend.mysecretlit.com/api/book/deleteBook/${id}`);
      if (res.status === 200) {
        setData(data.filter((item) => item.id !== id));
        toast.success('Book deleted successfully!');
      } else {
        toast.error('Failed to delete book. Please try again.');
      }
    } catch (error) {
      console.error('Error making API call:', error);
      toast.error('Failed to delete book. Please try again.');
    }
  };
    const handleViewDetails = (params) => {
      navigation('/Dashboard/add-audio-books/audio-book-details', { state: { data: params } });
    };
    const actionColumn = [
      {
        field: "action",
        headerName: "Action",
        width: 150,
        renderCell: (params) => {
          return (
            <div className="cellAction">
     
                <div onClick={()=>handleViewDetails(params.row)} className="viewButton">Edit</div>
              <div
                className="deleteButton"
                onClick={() => handleDelete(params.row.id)}
              >
                Delete
              </div>
            </div>
          );
        },
      },
    ];
    const filteredData = data.filter(book => 
      book.title.toLowerCase().includes(searchQuery.toLowerCase())
    );
  return (
    <div className='list'>
        <Sidebar/>
        <div className="listContainer">
        <Navbar onchange={(e) => setSearchQuery(e.target.value)} headingmain="All Audio Books Data" showSearchBar={true}/>
        <div className="add-categroy-name" style={{width:'97%',margin:'auto',marginTop:30,paddingRight:10}}>
            <div className="auidoBook-div-sub-coln"><h2 className='heading-h2-all-main' style={{color:'#ee2d2f',fontSize:22}}>Add New Audio Book</h2></div>
            <div className="auidoBook-div-sub-coln"><button className="addButton" onClick={()=>navigation('/Dashboard/add-audio-books/add-new-audio-book-add')} style={{width:'13em',background:'#ee2d2f',color:'white'}}>Add Audio Book</button></div>
        </div>
        <div className="datatable" >
        <ToastContainer/>
      {isLoading ? (
        <div className="loadingSpinner">
          <CircularProgress color="error" />
        </div>
      ) : (
      <DataGrid
        className="datagrid"
        rows={filteredData}
        columns={AudioBooksData.concat(actionColumn)}
        pageSize={100}
        rowsPerPageOptions={[120]}
        checkboxSelection
      />)}
    </div>
        <div className='all-book-style'>
        </div>
        
      </div>
    </div>
  )
}

export default AddAudioBooks