
// import "./datatable.scss";
// import { DataGrid } from "@mui/x-data-grid";
// import { userColumns } from "../../datatablesource"; // Assuming userColumns is imported correctly
// import { useNavigate } from "react-router-dom";
// import { useEffect, useState } from "react";
// import axios from "axios";
// import CircularProgress from "@mui/material/CircularProgress";
// import { ToastContainer, toast } from "react-toastify";

// const Datatable = ({searchQuery}) => {
//   const [data, setData] = useState([]);
//   const [isLoading, setIsLoading] = useState(false);

//   const navigation = useNavigate();

//   const fetchData = async () => {
//     setIsLoading(true);
//     try {
//       const response = await axios.get('https://new-app-testing-2d30280db142.herokuapp.com/api/get-all-users');
//       const formattedData = response.data.findAllUsers.map((item, index) => ({
//         ...item,
//         id: item._id
//       }));
      
//       setData(formattedData);
//       console.log(response.data.findAllUsers)
//     } catch (error) {
//       console.error('Error fetching data:', error);
//     } finally {
//       setIsLoading(false);
//     }
//   };

//   useEffect(() => {
//     fetchData();
//   }, []);

 

//   const handleViewDetails = (params) => {
//         navigation('/users/view-details/', { state: { data: params } });
//       };

//   const filteredData = data.filter(user =>
//     user.firstname.toLowerCase().includes(searchQuery.toLowerCase()) ||
//     user.lastname.toLowerCase().includes(searchQuery.toLowerCase()) ||
//     user.email.toLowerCase().includes(searchQuery.toLowerCase())
//   );
//   const handleSuspend = async (id) => {
    
//     try {
//       const res = await axios.post(`https://new-app-testing-2d30280db142.herokuapp.com/api/user/suspend/${id}`);
//       if (res.status === 200) {
//         setData(data.filter((item) => item.id !== id));
//         toast.success('user successfully suspended')
//       } else {
//         console.error('Failed to delete user. Please try again.');
//       }
//     } catch (error) {
//       console.error('Error making API call:', error);
//     }
//   };
//   const RefundedPlan = (id)=>{
// console.log("refunded",id)
//   }
//   const actionColumn = {
//     field: "action",
//     headerName: "Action",
//     width: 330,
//     renderCell: (params) => {
//       return (
//         <div className="cellAction">
//           <div onClick={() => handleViewDetails(params.row)} className="viewButton">View</div>
//           <div className="deleteButton" onClick={() => handleSuspend(params.row.id)}>Suspend</div>
//           <div className="deleteButton" onClick={() => RefundedPlan(params.row.id)}>Plan Refunded</div>
//         </div>
//       );
//     },
//   };

//   return (
//     <div className="datatable">
//        <ToastContainer/>
//       {isLoading ? (
//         <div className="loadingSpinner">
//           <CircularProgress color="error" />
//         </div>
//       ) : (  
//           <DataGrid
//             className="datagrid"
//             rows={filteredData}
//             columns={[...userColumns, actionColumn]}
//             pageSize={100}
//             rowsPerPageOptions={[10, 15, 20,170]}
//           />
      
//       )}
//     </div>
//   );
// };

// export default Datatable;
import "./datatable.scss";
import { DataGrid } from "@mui/x-data-grid";
import { userColumns } from "../../datatablesource"; // Assuming userColumns is imported correctly
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
import CircularProgress from "@mui/material/CircularProgress";
import { ToastContainer, toast } from "react-toastify";
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from "@mui/material";

const Datatable = ({ searchQuery }) => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const token = localStorage.getItem('maintoken')
  const navigation = useNavigate();

  // const fetchData = async () => {
  //   setIsLoading(true);
  //   try {
  //     const response = await axios.get('https://backend.mysecretlit.com/api/getAllUsers', {
  //       headers: {
  //         Authorization:`Bearer${token}`
  //       }
  //     });
  const fetchData = async () => {
    const token = localStorage.getItem('maintoken'); // Retrieve token from local storage
    const apiUrl = 'https://backend.mysecretlit.com/api/getAllUsers'; // Replace with your API endpoint

    try {
      const response = await axios.get(apiUrl, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
  
      // Filter out objects where isSuspended is true and then map the remaining objects
      const formattedData = response.data?.data.reverse()
        .filter(item => !item.isSuspended) // Exclude objects where isSuspended is true
        .map((item) => ({
          ...item,
          id: item._id,
        }));
  
      setData(formattedData);
      console.log("Filtered user data", formattedData);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setIsLoading(false);
    }
  };
  

  useEffect(() => {
    fetchData();
  }, []);

  const handleViewDetails = (params) => {
    navigation('/users/view-details/', { state: { data: params } });
  };

  const filteredData = data.filter(user =>
    user.firstName.toLowerCase().includes(searchQuery.toLowerCase()) ||
    user.lastName.toLowerCase().includes(searchQuery.toLowerCase()) ||
    user.email.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const handleSuspend = async (id) => {
    const token = localStorage.getItem('maintoken');
    try {
      const res = await axios.post('https://backend.mysecretlit.com/api/suspendUser',{status:'true',userId:id},{
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (res.status === 200) {
        setData(data.filter((item) => item.id !== id));
        toast.success('User successfully suspended');
      } else {
        console.error('Failed to suspend user. Please try again.');
      }
    } catch (error) {
      console.error('Error making API call:', error);
    }
  };

  const handleRefundedPlan = async (id) => {
    try {
      const res = await axios.post(`https://new-app-testing-2d30280db142.herokuapp.com/api/sab-admin/refund/${id}`);
      if (res.status === 200) {
        toast.success('Plan successfully refunded');
      } else {
        console.error('Failed to refund plan. Please try again.');
      }
    } catch (error) {
      console.error('Error making API call:', error);
    }
    console.log(id)
  };

  const openRefundDialog = (id) => {
    setSelectedUserId(id);
    setOpenDialog(true);
  };

  const closeRefundDialog = () => {
    setOpenDialog(false);
    setSelectedUserId(null);
  };

  const confirmRefund = () => {
    if (selectedUserId) {
      handleRefundedPlan(selectedUserId);
    }
    closeRefundDialog();
  };

  const actionColumn = {
    field: "action",
    headerName: "Action",
    width: 330,
    renderCell: (params) => {
      return (
        <div className="cellAction">
          <div onClick={() => handleViewDetails(params.row)} className="viewButton">View</div>
          <div className="deleteButton" onClick={() => handleSuspend(params.row.id)}>Suspend</div>
          <div className="deleteButton" onClick={() => openRefundDialog(params.row.id)}>Plan Refunded</div>
        </div>
      );
    },
  };

  return (
    <div className="datatable">
      <ToastContainer />
      {isLoading ? (
        <div className="loadingSpinner">
          <CircularProgress color="error" />
        </div>
      ) : (
        <DataGrid
          className="datagrid"
          rows={filteredData}
          columns={[...userColumns, actionColumn]}
          pageSize={100}
          rowsPerPageOptions={[10, 15, 20, 170]}
        />
      )}

      <Dialog
        open={openDialog}
        onClose={closeRefundDialog}
        aria-labelledby="refund-dialog-title"
        aria-describedby="refund-dialog-description"
      >
        <DialogTitle id="refund-dialog-title">Confirm Plan Refund</DialogTitle>
        <DialogContent>
          <DialogContentText id="refund-dialog-description">
            Are you sure you want to refund this user's plan?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeRefundDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={confirmRefund} color="secondary" autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Datatable;
